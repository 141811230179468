<template>
  <div class="auth">
    <div v-if="user.reset_password_token">
      <form @submit="reset_password" class="form" method="post">
        <div class="form-wrap">
          <input v-model="user.password" class="form-input" type="password" name="password" id="password" placeholder="Neues Passwort" />
        </div>

        <div class="form-wrap">
          <input
            v-model="user.password_confirmation"
            class="form-input"
            type="password"
            name="password_confirmation"
            id="password_confirmation"
            placeholder="Neues Passwort wiederholen"
          />
        </div>

        <p style="text-align: right">
          <input type="submit" value="Neues Passwort speichern" class="button button-ci button-round button-100" />
        </p>

        <div class="auth-meta">
          <p>{{ $t("auth.noReset") }}</p>
          <p class="sign_up">
            <router-link to="/auth/sign_in" class="link">{{ $t("auth.loginButton") }}</router-link>
          </p>
        </div>
      </form>
    </div>
    <div v-else>
      <div class="alert alert-info">{{ $t("auth.tokenAlert") }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "auth-sign-in",
  data() {
    return {
      user: {
        reset_password_token: "",
        password: "",
        password_confirmation: "",
      },
      headers: {
        token: "",
        uid: "",
        client: "",
      },
    };
  },
  methods: {
    verify_token() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/auth/password/edit?reset_password_token=" + this.user.reset_password_token, { headers: {} })
        .then((response) => {
          this.headers.client = response.data.client;
          this.headers.uid = response.data.email;
          this.headers.token = response.data.token;
        });
    },
    reset_password: function (e) {
      e.preventDefault();

      axios
        .put(
          process.env.VUE_APP_BASE_API + "/auth/password?reset_password_token=" + this.user.reset_password_token,
          {
            password: this.user.password,
            password_confirmation: this.user.password_confirmation,
          },
          {
            headers: {
              "access-token": this.headers.token,
              client: this.headers.client,
              uid: this.headers.uid,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("auth.resetConfirm"),
          });
          this.$router.push("/auth/sign_in");
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            text: error.response.data.errors,
          });
        });
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.user.reset_password_token = this.$route.query.token;
      this.verify_token();
    }
  },
};
</script>
